import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import firebase from "../../config/firebaseconfig";
// import firebase from "gatsby-plugin-firebase";
import "./PopupNotification.css";

const PopupNotification = () => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    let lastday = localStorage.getItem("Notfied") ?? false;
    if (lastday != true) {
      if (lastday < new Date().getTime()) {
        setTimeout(() => {
          setShow(true);
        }, 1000 * 30);
      }
    }
  }, []);

  const onSubmitHandel = async () => {
    let pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    let isEmail = pattern.test(email);

    if (isEmail) {
      firebase
        .firestore()
        .collection("subscriber")
        .add({
          email: email,
        })
        .then((ref) => {
          setShow(false);
          setEmail("");
          localStorage.setItem("Notfied", true);
          console.log("Added document with ID: ", ref.id);
        });
    }
  };

  const onCancel = () => {
    setShow(false);
    localStorage.setItem(
      "Notfied",
      new Date().getTime() + 1000 * 60 * 60 * 24 * 7
    );
  };

  return (
    <div>
      <Modal
        style={{ top: 20, textAlign: "center" }}
        visible={show}
        onCancel={() => onCancel()}
        onOk={() => onSubmitHandel()}
        okText="Submit"
        cancelText="Later"
      >
        <h3 className="subscribe_title">Subscribe To Our News Later</h3>
        <p className="description_title">
          Light Safe Candle is dedicated to serve the community of churches and
          church service with improving safety and finances.
        </p>
        <input
          className="input_email"
          placeholder="Enter Your Email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </Modal>
    </div>
  );
};

export default PopupNotification;
