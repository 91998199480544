import React from "react";
import { useStaticQuery, graphql, navigate } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Button from "../components/Shared/Button";
import ContactForm from "../components/ContactForm";
import { withPrefix } from "gatsby-link";
import PopupNotification from "../components/Popup/PopupNotification";
import "antd/dist/antd.css";
let s = {};

const IndexPage = ({ location }) => {
  if (location.pathname === withPrefix("/")) {
    s = require("./index.module.css");
  } else {
    console.log("fail");
  }
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "hero-img.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      aboutUsImage: file(relativePath: { eq: "about-img.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <PopupNotification />
      <SEO title="Light a candle" />
      <div className="container home">
        <div className={s.heroContainer}>
          <Img
            className={s.heroImg}
            fluid={data.heroImage.childImageSharp.fluid}
          />
          <div className={s.heroLight}>
            <h4 className={s.heroLightTitle}>
              Here You can light a candle online to pray for Your loved ones
            </h4>
            <Button
              className="center"
              onClick={() => navigate("/churches", { replace: true })}
            >
              LIGHT A CANDLE
            </Button>
          </div>
        </div>
        <a id="about" name="about">
          <div className="d-flex flex-wrap-mobile">
            <div className={s.aboutUs}>
              <h2 className={s.subTitle}>About Us</h2>
              <p className="text">
                Light Safe Candle is dedicated to serve the community of
                churches and church service with improving safety and finances.
                This is the ultimate solution to help churches improve safety
                from fire and other damages related to open fire candle station
                on the building by providing safe low voltage LED light bold
                candles.Our team has developed unique Patented software and
                hardware to provide spiritual service by allowing a user to
                light a candle in any selected church directly from the
                smartphone or browser with additional option display of desired
                text and photo to be displayed in the church. Engage more people
                ONLINE to help donate and support the church. Keeping People
                safe due to pandemic. Maintenance Free Light Safe Candle service
                is reusable candle station with 24/7 tech support. Lower
                insurance fee eliminating open fire threat.
              </p>
            </div>
            <div className={s.aboutUsImageWrapper}>
              <Img
                className={s.aboutUsImage}
                fluid={data.aboutUsImage.childImageSharp.fluid}
              />
              <div className={s.aboutUsImagePlaceholder} />
            </div>
          </div>
        </a>
      </div>
      <a id="contact" name="contact">
        <div className={s.formWrapper}>
          <ContactForm />
        </div>
      </a>
    </Layout>
  );
};

export default IndexPage;
