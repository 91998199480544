import firebase from "firebase/app";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCXbAEA5ykNz2luU38mnRrOvqH573KAFA0",
  authDomain: "light-safe-candle.firebaseapp.com",
  projectId: "light-safe-candle",
  storageBucket: "light-safe-candle.appspot.com",
  messagingSenderId: "965651254004",
  appId: "1:965651254004:web:80ee939884ddd0d9d1eca1",
  measurementId: "G-5RNMZFSH7C",
};
firebase.initializeApp(firebaseConfig);

export default firebase;

// module.exports = {
//   plugins: [
//     ...otherPlugins,

//     {
//       resolve: "gatsby-plugin-firebase",
//       options: {
//         credentials: {
//           apiKey: "AIzaSyCXbAEA5ykNz2luU38mnRrOvqH573KAFA0",
//           authDomain: "light-safe-candle.firebaseapp.com",
//           projectId: "light-safe-candle",
//           storageBucket: "light-safe-candle.appspot.com",
//           messagingSenderId: "965651254004",
//           appId: "1:965651254004:web:80ee939884ddd0d9d1eca1",
//           measurementId: "G-5RNMZFSH7C",
//         },
//       },
//     },
//   ],
// };
