import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { sendEmail } from "../../api/email";
import Button from "../Shared/Button";
import Input from "../Shared/Input";

import s from "./ContactForm.module.css";

const schema = yup.object({
  name: yup.string().required(),
  email: yup.string().email().required(),
  text: yup.string().required(),
});

const ContactForm = () => {
  const [isLoading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      text: "",
    },
    onSubmit: async (values, formik) => {
      try {
        setLoading(true);
        await sendEmail(values.name, values.email, values.text);
        toast.success("You`ve successfully sent an email");
        formik.resetForm();
      } catch (e) {
        toast.error("Failed to send an email. Try again later");
      } finally {
        setLoading(false);
      }
    },
    validationSchema: schema,
  });

  return (
    <form className={s.form} onSubmit={formik.handleSubmit}>
      <h2 className="subTitle">Contact Us</h2>
      <p className="secondary-text">
        Please fill out the form below and we’ll get back to you.
      </p>
      <Input
        id="name"
        type="text"
        label="Name"
        placeholder="Name"
        isInvalid={formik.touched.name && formik.errors.name}
        {...formik.getFieldProps("name")}
      />
      <Input
        id="name"
        type="email"
        label="Email"
        placeholder="Email"
        isInvalid={formik.touched.email && formik.errors.email}
        {...formik.getFieldProps("email")}
      />
      <Input
        id="text"
        type="textarea"
        label="Text"
        placeholder="Text"
        isInvalid={formik.touched.text && formik.errors.text}
        {...formik.getFieldProps("text")}
      />
      <div className="ml-auto">
        <Button type="submit" isLoading={isLoading}>
          Send
        </Button>
      </div>
    </form>
  );
};

export default ContactForm;
